import React from 'react';
import * as detail from './../css/bootcampDetail.module.css';
import { useTranslation } from "react-i18next";


export function DetailFullScope(props) {
    const { t } = useTranslation('bootcamp');
    return(
    <div className={detail.contentBlock}>
        <button value="fullScope" onClick={props.close}>X</button>
        <p>{t("detailFullScopeP1")}</p>
        <p>{t("detailFullScopeP2")}</p>
        <p>{t("detailFullScopeP3")}</p>
    </div>
    )
}

export function DetailSegmentation(props) {
    const { t } = useTranslation('bootcamp');
    return(
    <div className={detail.contentBlock}>
        <button value="segmentation" onClick={props.close}>X</button>
        <p>{t("detailSegmentationP1")}</p>
        <p>{t("detailSegmentationP2")}</p>
    </div>
    )
}

export function DetailValueProposition(props) {
    const { t } = useTranslation('bootcamp');
    return(
    <div className={detail.contentBlock}>
        <button value="valueProp" onClick={props.close}>X</button>
        <p>{t("detailValuePropP1")}</p>
        <p>{t("detailValuePropP2")}</p>
        <p>{t("detailValuePropP3")}</p>
    </div>
    )
}

export function DetailBusinessCase(props) {
    const { t } = useTranslation('bootcamp');
    return(
    <div className={detail.contentBlock}>
        <button value="businessCase" onClick={props.close}>X</button>
        <p>{t("detailBusinessCaseP1")}</p>
        <p>{t("detailBusinessCaseP2")}</p>
    </div>
    )
}

export function DetailLaunch(props) {
    const { t } = useTranslation('bootcamp');
    return(
    <div className={detail.contentBlock}>
        <button value="launch" onClick={props.close}>X</button>
        <p>{t("detailLaunchP1")}</p>
        <p>{t("detailLaunchP2")}</p>
    </div>
    )
}

export function DetailTailored(props) {
    const { t } = useTranslation('bootcamp');
    return(
    <div className={detail.contentBlock}>
        <button value="tailored" onClick={props.close}>X</button>
        <p>{t("detailTailoredP1")}</p>
        <p>{t("detailTailoredP2")}</p>
    </div>
    )
}