import * as React from "react"
import { useTranslation } from "react-i18next";
import { StaticImage } from "gatsby-plugin-image";
import { Link, animateScroll as scroll } from 'react-scroll';
import Layout from "../components/layout"
import Seo from "../components/seo"
import * as bootcamp from './../css/bootcamp.module.css';
import CallToAction from "../components/CallToAction";
import { useState } from 'react';
import { DetailFullScope, DetailSegmentation, DetailValueProposition, DetailBusinessCase, DetailLaunch, DetailTailored} from "../components/BootcampDetails";

const Bootcamp = () => {
  const { t } = useTranslation('bootcamp');

  const [detailBoxStatus, setDetailBoxStatus] = useState({
    fullScope:false,
    segmentaion:false,
    valueProp:false,
    businessCase:false,
    launch:false,
    tailored:false
  });

  const togglePopUp = (event) => {
    //console.log('Clicked: ' + JSON.stringify(event.target.value));
    setDetailBoxStatus((prevStatus) => ({
      ...prevStatus,
      [event.target.value]: !prevStatus[event.target.value]
    }))
  }

  const scrollToTop = () => {
    scroll.scrollToTop();
  };


  return (
  <Layout>
    <Seo title="Bootcamp" />
      <section>
        <div id={bootcamp.firstSection} className={bootcamp.sectionContent}>
          <h2>Bootcamp</h2>
          <p>{t("section1P1")}</p>
          <p>{t("section1P2")}</p>
          <p>{t("section1P3")}</p>
          <p>{t("section1P4")}</p>
        
          <Link 
            className={bootcamp.chevron}
            to="second_section"
            spy={true}
            smooth={true}
            offset={-120}
            duration={1000}>
            <StaticImage
              src="../images/chevron_red.png"
              formats={["auto", "webp", "avif"]}
              alt="Arrow down"
            />
          </Link>
        </div>
        
      </section>

      <section>
        <div className={bootcamp.sectionContent}>
          <div className={bootcamp.examples} id="second_section" >
            <div className={bootcamp.exampleBox}>
                <p className={bootcamp.exampleText}>{t("bootCamp1")}</p>
                <p className={bootcamp.exampleDuration}>5 {t("days")}</p>
                <button value="fullScope" onClick={togglePopUp}>>></button>
              <div className={bootcamp.detailBox}>
                <div className={detailBoxStatus.fullScope ? bootcamp.detailBoxShow: bootcamp.detailBoxHide}>
                  <DetailFullScope close={togglePopUp} />
                </div>
              </div>
            </div>

            <div className={bootcamp.exampleBox}>
                <p className={bootcamp.exampleText}>{t("bootCamp2")}</p>
                <p className={bootcamp.exampleDuration}>2 {t("days")}</p>
                <button value="segmentation" onClick={togglePopUp}>>></button>
              <div className={bootcamp.detailBox}>
                <div className={detailBoxStatus.segmentation ? bootcamp.detailBoxShow: bootcamp.detailBoxHide}>
                  <DetailSegmentation close={togglePopUp} />
                </div>
              </div>
            </div>
            
            <div className={bootcamp.exampleBox}>
                <p className={bootcamp.exampleText}>{t("bootCamp3")}</p>
                <p className={bootcamp.exampleDuration}>2.5 {t("days")}</p>
                <button value="valueProp" onClick={togglePopUp}>>></button>
              <div className={bootcamp.detailBox}>
                <div className={detailBoxStatus.valueProp ? bootcamp.detailBoxShow: bootcamp.detailBoxHide}>
                  <DetailValueProposition close={togglePopUp} />
                </div>
              </div>
            </div>
            
            <div className={bootcamp.exampleBox}>
                <p className={bootcamp.exampleText}>{t("bootCamp4")}</p>
                <p className={bootcamp.exampleDuration}>1 {t("day")}</p>
                <button value="businessCase" onClick={togglePopUp}>>></button>
              <div className={bootcamp.detailBox}>
                <div className={detailBoxStatus.businessCase ? bootcamp.detailBoxShow: bootcamp.detailBoxHide}>
                  <DetailBusinessCase close={togglePopUp} />
                </div>
              </div>
            </div>
            
            
            <div className={bootcamp.exampleBox}>
                <p className={bootcamp.exampleText}>{t("bootCamp5")}</p>
                <p className={bootcamp.exampleDuration}>1 {t("day")}</p>
                <button value="launch" onClick={togglePopUp}>>></button>
              <div className={bootcamp.detailBox}>
                <div className={detailBoxStatus.launch ? bootcamp.detailBoxShow: bootcamp.detailBoxHide}>
                  <DetailLaunch close={togglePopUp} />
                </div>
              </div>
            </div>
            
            <div className={bootcamp.exampleBox}>
                <p className={bootcamp.exampleText}>{t("bootCamp6")}</p>
                <p className={bootcamp.exampleDuration}>X {t("days")}</p>
                <button value="tailored" onClick={togglePopUp}>>></button>
              <div className={bootcamp.detailBox}>
                <div className={detailBoxStatus.tailored ? bootcamp.detailBoxShow: bootcamp.detailBoxHide}>
                  <DetailTailored close={togglePopUp} />
                </div>
              </div>
            </div>

          </div>
          
          <p className={bootcamp.callOutBlue}>{t("section2CallOut")}</p>
         
        </div>
      </section>

      <section>
        <CallToAction />
      </section>

      <div className={bootcamp.scrollUp}>
        <button
         onClick={scrollToTop}>
          <StaticImage
                  src="../images/arrowup_grey.png"
                  height={50}
                  formats={["auto", "webp", "avif"]}
                  alt="Back to top"
                />
        </button>
      </div>
    
  </Layout>
)}

export default Bootcamp
