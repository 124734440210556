// extracted by mini-css-extract-plugin
export var sectionContent = "bootcamp-module--section-content--b2c5d";
export var firstSection = "bootcamp-module--first-section--82685";
export var chevron = "bootcamp-module--chevron--197d7";
export var examples = "bootcamp-module--examples--67d6a";
export var exampleBox = "bootcamp-module--example-box--545c1";
export var exampleText = "bootcamp-module--example-text--d72eb";
export var exampleDuration = "bootcamp-module--example-duration--20079";
export var callOutBlue = "bootcamp-module--call-out-blue--307fb";
export var detailBox = "bootcamp-module--detail-box--ba55b";
export var detailBoxShow = "bootcamp-module--detail-box-show--f7b13";
export var detailBoxHide = "bootcamp-module--detail-box-hide--7ec1d";
export var scrollUp = "bootcamp-module--scroll-up--fc28f";